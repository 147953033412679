import * as React from "react"

const FullScreenToggle = ({ active }) => {
  return active ?
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" space="preserve" fill="none">
      <g>
        <rect x="0" width="30" height="30" />
      </g>
      <g style={{ opacity: 0.6 }}>
        <rect x="5.25" y="7.75" width="19.5" height="14.5" />
        <path fill="white" d="M24.5,8v14h-19V8H24.5 M25,7.5H5v15h20V7.5L25,7.5z" />
      </g>
      <g>
        <rect x="8.96" y="11.5" width="12.08" height="7" />
        <path fill="white" d="M20.79,11.75v6.5H9.21v-6.5H20.79 M21.29,11.25H8.71v7.5h12.58V11.25L21.29,11.25z" />
      </g>
    </svg>
    :
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 30 30" space="preserve" fill="none">
      <g>
        <rect width="30" height="30" />
      </g>
      <g>
        <rect x="5.25" y="7.75" width="19.5" height="14.5" />
        <path fill="white" d="M24.5,8v14h-19V8H24.5 M25,7.5H5v15h20V7.5L25,7.5z" />
      </g>
      <g style={{ opacity: 0.6 }}>
        <rect x="8.96" y="11.5" width="12.08" height="7" />
        <path fill="white" d="M20.79,11.75v6.5H9.21v-6.5H20.79 M21.29,11.25H8.71v7.5h12.58V11.25L21.29,11.25z" />
      </g>
    </svg>
}

export default FullScreenToggle