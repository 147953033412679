import { gql } from "@apollo/client"

const ALBUM = gql`
  query allAlbums($username: String!, $slug: String!) {
    userByUsername(username: $username) {
      albums(filter: { slug: { equalTo: $slug } }) {
        id
        title
        slug
        description
        keyValues {
          id
          name
          value
        }
        tags {
          id
          name
        }
        slides(orderBy: POS_ASC, filter: { hidden: { equalTo: false } }) {
          id
          image {
            id
            secret
          }
          video {
            id
            secret
          }
          videoUrl
          width
          height
          title
          description
          slideTags: tags {
            id
            name
          }
        }
        slidesConnection {
          totalCount
        }
      }
    }
  }
`

export default ALBUM
