import * as React from "react";
import { useRef } from "react";
import styled from "styled-components";
import justifiedLayout from "justified-layout";
import useComponentSize from "@rehooks/component-size";
import View from "./View";
import { Image as SlideImage, RatioContainer } from ".";
import Text from "./Text";
import { Link } from "react-router-dom";
import historyLength from "../utilities/historyLength";
import { useNavigate } from "react-router-dom";

import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

const scrollIntoViewSmoothly =
  "scrollBehavior" in document.documentElement.style
    ? scrollIntoView
    : smoothScrollIntoView;

function indexOfSmallest(a) {
  return a.indexOf(Math.min.apply(Math, a));
}

function n(n) {
  return n > 9 ? "" + n : "0" + n;
}

const Container = styled(View)`
  display: flex;
  flex-wrap: wrap;
`;

const Number = styled(Text).attrs(() => ({
  mt: 2,
  textStyle: "caption",
  color: "text",
}))`
  position: absolute;
  left: 0;
  top: 100%;
`;

const imageRatios = [16 / 9, 3 / 2, 4 / 3, 1 / 1, 3 / 4, 2 / 3];

const AlbumIndex = ({ album, close }) => {
  const slides = album?.slides;
  /*
  const ref = useRef()

  let { width: containerWidth, height } = useComponentSize(ref)

  const aspectRatios = slides.map((slide) => slide.width / slide.height)
  const layout = justifiedLayout(aspectRatios, {
    containerWidth,
    targetRowHeight: containerWidth < 80,
    containerPadding: containerWidth < 12,
    boxSpacing: containerWidth < 24,
  })
*/

  const basePercent = slides ? Math.round(80 / Math.sqrt(slides.length)) : null;

  const navigate = useNavigate();

  return (
    <Container>
      {slides?.map((slide, index) => {
        // find out which is closest?!
        const ratio =
          imageRatios[
            indexOfSmallest(
              imageRatios.map((ratio) =>
                Math.abs(ratio - slide.width / slide.height)
              )
            )
          ];

        return (
          <View
            as={Link}
            to="../"
            hover
            pointer
            key={slide.id}
            style={{ width: `${basePercent * ratio}%`, position: "relative" }}
            mr={1}
            mb={8}
            replace
            onClick={(e) => {
              e.preventDefault();
              const el = window.document.getElementById(slide.id);
              console.log("ELEMENT", historyLength(), el);
              if (!!el) {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
              // if (historyLength() > 0) {
              console.log("history back");
              navigate("../", { replace: true });
              // }
            }}
          >
            <RatioContainer ratio={ratio}>
              <SlideImage slide={slide} />
            </RatioContainer>
            <Number>{n(index + 1)}</Number>
          </View>
        );
      })}
    </Container>
  );
};

export default AlbumIndex;
