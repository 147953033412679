export const textStyles = (textStyle = "body") => ({
  textStyleProp = textStyle,
  fontWeight,
  theme: { textStyles },
}) => {
  const styleSpecs = textStyles[textStyleProp]
  return `
      font-family: ${styleSpecs.fontFamily};
      font-size: ${styleSpecs.fontSize};
      font-weight: ${
        fontWeight
          ? styleSpecs[fontWeight]
          : "fontWeight" in styleSpecs
          ? styleSpecs[styleSpecs.fontWeight]
          : styleSpecs["normal"]
      };
      line-height: ${styleSpecs.lineHeight};
      letter-spacing: ${styleSpecs.letterSpacing}em;
      text-transform: ${styleSpecs.textTransform};
      letter-spacing: ${styleSpecs.letterSpacing}em;
      padding: 0.05px 0px;
      ${
        styleSpecs.baselineCorrection &&
        `
        &::after {	
          content: "";	
          margin-bottom: -${styleSpecs.baselineCorrection}em;	
          display: block;	
          height: 0;	
        }
      `
      }
      ${
        styleSpecs.heightCorrection &&
        `
      &::before {	
        content: "";	
        margin-top: -${styleSpecs.heightCorrection}em;	
        display: block;	
        height: 0;	
      }
      `
      }
    `
}

export default textStyles

/*
  
  const openSansStyle: {
    fontFamily: `'Open Sans', sans-serif`,
    normal: 300,
    bold: 700,
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0
  }
  
  configure your text styles by providing the following object to your styled-components ThemeProvider:
  
  const textStyles = {
    title: openSansStyle,
    headline: { ...openSansStyle, fontSize: 32, weight: 'bold' },
    body: openSansStyle,
    callout: openSansStyle,
    subhead: openSansStyle,
    footnote: { ...openSansStyle, fontSize: 12 },
    caption: openSansStyle
  }
  
  usage:
  
  const Headline = styled.h1`
    ${textStyles('headline')};
    ${color};
  `
  
  override:
  
  <Headline textStyle="body" fontWeight="normal">Hello World!</Headline>
  
  */
