import * as React from "react"
import { View, Text, Image } from "./"
import { Link } from "react-router-dom"
import useCount from "../utilities/useCount"
import { animated, useSpring, config } from "react-spring"
import styled from "styled-components"
import useMousePosition from "@react-hook/mouse-position"
import useWindowFocus from "use-window-focus"
import useInterval from "../utilities/useInterval"
import CoverVideo from "./CoverVideo"
import { useAtom } from "jotai"
import hoverItemAtom from "../hoverItemAtom"
import Delay from "./Delay"
import Item from "./InfiniteCarouselItem"

import useDimensions from "react-use-dimensions";
import { useGesture } from 'react-use-gesture'
import { useInertia } from '../utilities/useInertia'
import { contentArea } from "../Home"


const Container = styled(View)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  a {
    user-select: none;
    -webkit-user-drag: none;
  }
`

const ImageContainer = styled(View).attrs(() => ({ bg: "#EEE" }))`
  position: relative;
  width: 91.5%;
  height: 0;
  padding-bottom: ${(91.5 / 3) * 4}%;
`

const speedFactor = 15 // 30 seconds intil next

const InfiniteCarousel = ({ slides, direction = "up", paused, style, full }) => {

  // const [ref, { height }] = useDimensions()
  const ref = React.useRef(null)
  const [rect] = useAtom(contentArea)

  const directionFactor = direction === "up" ? -100 : 100
  const directionFactorCorrect = direction === "up" ? 1 : -1

  const mousePosition = useMousePosition(ref, {
    enterDelay: 0,
    leaveDelay: 0,
    fps: 10,
  })
  const active = !mousePosition.x

  const windowFocused = useWindowFocus()

  const count = useInterval(
    windowFocused === true && active === true && paused === false,
    10
  )

  const [value, setValue] = React.useState(0)
  const [props, set, stop] = useSpring(() => ({
    transform: `translateY(${(count / speedFactor) * directionFactor}%)`,
    position: (count / speedFactor) * directionFactor,
    config: { mass: 1, tension: 500, friction: 120, precision: 0.5 },
    onFrame: ({ position }) => setValue(position),
  }))
  const [prevCount, setPrevCount] = React.useState(count)
  React.useEffect(() => {
    // set({ position: count })
    // console.log(count, prevCount)
    set({
      transform: `translateY(${(count / speedFactor) * directionFactor}%)`,
      position: (count / speedFactor) * directionFactor,
      // reset: Math.abs(prevCount - count > 1),
    })
    setPrevCount(count)
  }, [count, set, directionFactor])
  // spring

  const correct =
    Math.floor(count / speedFactor / slides.length) * directionFactorCorrect

  const randomStartSlides = React.useMemo(() => {
    const rndm = Math.floor(Math.random() * slides.length)
    const randomStartSlides = [...slides, ...slides].slice(
      rndm,
      rndm + slides.length
    )
    console.log("NEW SETJE?!")
    return randomStartSlides
  }, [])

  const [_, setHover] = useAtom(hoverItemAtom)

  // manual position manipulation:
  // const [{ y }, setInertia] = useInertia({ y: 0 })
  const wHeight = window.innerHeight

  // const height = 455

  const [dragDelta, setDragDelta] = React.useState(0)

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [, my], vxvy: [, vy] }) => {
        if (down) {
          // setInertia({ y: my, config: { decay: false, velocity: 0 } })
          console.log("DONW!!")
          console.log({ my, vy, rect })
          console.log(((1 / rect.height) * vy))
          console.log({ value })
          // setDragDelta(delta => delta + ((1000 / rect.height) * vy))
          setDragDelta(delta => delta + (8 * vy))

        } else {
          console.log({ my, vy })
          /*
          setInertia({
            y: my,
            config: {
              inertia: true,
              bounds: { y: [-height + wHeight, 0] },
              velocities: { y: vy }
            }
          })
          */
        }
      },
      onWheel: ({ delta: [, dy] }) => {
        /*
        setInertia({
          y: Math.max(-height + wHeight, Math.min(y.get() - dy * 3, 0)),
          config: config.stiff
        })
        */
      }
    },
    {
      drag: {
        initial: () => [0, 0],
        // bounds: { top: -height + wHeight, bottom: 0 },
        rubberband: true
      }
    }
  )

  return (
    <Container ref={ref} style={style} {...bind()}>
      {
        randomStartSlides.map((slide, index) =>
          <Item key={slide.id}
            slide={slide}
            slidesCount={slides.length}
            value={value + dragDelta}
            index={index}
            correct={(index * directionFactorCorrect + correct * slides.length) * 101.25}
            space={1.25}
          >{(visible, top) => (
            <ImageContainer
              key={slide.id}
              as={Link}
              style={{
                display: visible ? "block" : "none",
                left: direction === "down" ? "8.5%" : 0,
                top: 0,
                ...full && { width: '100%', height: "100%", overflow: "hidden" },
                ...props,
                position: "absolute",
                transform: `translateY(${top})`,
              }}
              to={slide.slug}
              onMouseEnter={() => setHover(slide.albumId)}
              onMouseLeave={() => setHover(null)}
              onMouseOut={() => setHover(null)}
            >
              {slide.videoUrl || slide.video ? (
                <CoverVideo url={slide.videoUrl} video={slide.video} playing={visible} />
              ) : visible ? (
                <Image fade={true} lazy={false} cover={true} slide={slide} />
              ) : null}
            </ImageContainer>
          )}</Item>)
      }
    </Container>
  )
}

export default InfiniteCarousel
