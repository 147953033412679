import * as React from "react"
import styled from "styled-components"
import { space, color } from "styled-system"

const View = styled(
  React.forwardRef(({ space, color, ...props }, ref) => (
    <div {...props} ref={ref} />
  ))
)`
  ${space};
  ${color};
  ${(props) => (props.maxWidth ? props.theme.maxWidth : null)};
  ${({ center }) =>
    center === true &&
    `
    margin-left: auto;
    margin-right: auto;
  `};
  ${({ hover }) =>
    hover === true &&
    `
  :hover {
    opacity: 0.8;
  }
  transition: opacity 250ms ease-in-out;
`};
${({ pointer }) =>
    pointer === true &&
    `
cursor: pointer;
`};
${({ relative }) =>
    relative === true &&
    `
position: relative;
`};
${({ absolute }) =>
    absolute === true &&
    `
position: absolute;
`};
${({ block }) =>
    block === true &&
    `
display: block;
`};
`

export default View
