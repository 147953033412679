import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { View, Image as SlideImage, Text } from "./";
import Vimeo from "./Vimeo";
import useRect from "../utilities/useRect";
import { useAtom } from "jotai";
import { contentArea } from "../Home";
import { metaData } from "./Meta";
import { videoUrl } from "./CoverVideo";
import VideoPlayer from "./VideoPlayer";
import Delay from "./Delay";
import getKeyValue from "../utilities/getKeyValue";
import useDimensions from "react-use-dimensions";

const Container = styled(View)`
  position: relative;
`;

const Center = styled(View)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Slide = styled(View)`
  display: flex;
  flex-direction: row;
  ${({ justify }) => justify}
`;

const ImageContainer = styled(View)`
  position: relative;
  height: 0;
`;

const StreamContainer = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
`;

// container height volgt uit de inhoud EADDRINUSE, dus hoef ik niet expliciet in te stellen

const imageWidth = (imageRatio, containerRatio) =>
  `${100 * (imageRatio / containerRatio)}%`;
const imageHeight = (imageRatio, containerRatio) =>
  imageRatio > containerRatio
    ? `${100 / imageRatio}%`
    : `${(100 / imageRatio) * (imageRatio / containerRatio)}%`;

const slideStyles = (position) => {
  switch (position) {
    case "side-by-side": {
      return css`
        justify-content: space-between;
      `;
    }
    case "left": {
      return null;
    }
    case "right": {
      return css`
        justify-content: flex-end;
      `;
    }
    default: {
      return css`
        justify-content: center;
      `;
    }
  }
};
const getPosition = (tags) => {
  const possiblePositions = ["left", "right", "center", "side-by-side"];
  return possiblePositions.find((position) => tags.includes(position));
};

export default ({ album, isWide }) => {
  const slides = album.slides;

  const [rect] = useAtom(contentArea);
  const [_, setMetaData] = useAtom(metaData);
  console.log({ rect });
  // if (!rect) return null

  const containerRatio = rect.width ? rect.width / rect.height : 21 / 9.5;
  console.log({ containerRatio });

  useEffect(() => {
    if (slides.length === 1) {
      setMetaData({
        left: null,
        right: null,
        center: {
          title: album.title,
          director: getKeyValue(album, "Director"),
          client: getKeyValue(album, "Client"),
        },
      });
    } else {
      setMetaData({
        left: {
          title: album.title,
          director: getKeyValue(album, "Director"),
          client: getKeyValue(album, "Client"),
        },
        right: "INDEX",
        center: null,
      });
    }

    return () => setMetaData({});
  }, [album, setMetaData, slides.length]);

  const [ref, { width: galleryWidth }] = useDimensions();

  return (
    <Container
      bg="secondaryBackground"
      ref={ref}
      style={{
        paddingBottom: rect.top,
      }}
    >
      {isWide &&
        slides
          .reduce((collect, slide, index) => {
            // left, right, center, side-by-side
            const tags = slide.slideTags.map((tag) => tag.name);
            const position = getPosition(tags);
            const prevPosition = index > 0 && collect[index - 1].position;
            const prevSecondSideBySide =
              index > 0 && collect[index - 1].secondSideBySide;
            const secondSideBySide =
              prevPosition === "side-by-side" &&
              // position === "side-by-side" &&
              prevSecondSideBySide === false;
            return [
              ...collect,
              { ...slide, position, prevPosition, secondSideBySide },
            ];
          }, [])
          .map((slide, index) => {
            const { position, secondSideBySide } = slide;
            return secondSideBySide ? null : (
              <Center bg="secondaryBackground" key={index}>
                <div
                  style={{
                    width: rect.width || galleryWidth,
                    height: rect.height || galleryWidth / (3 / 2),
                    marginTop:
                      index === 0 ? rect.top : `calc(${rect.top}px - 3vh)`,
                    position: "relative",
                  }}
                >
                  <Slide
                    justify={slideStyles(position)}
                    key={slide.id}
                    style={{
                      width: "100%",
                      height: "100%",
                      ...(slides.length === 1
                        ? { marginBottom: 0 }
                        : { paddingBottom: 0 }),
                      position: "absolute",
                      top: 0,
                    }}
                  >
                    <ImageContainer
                      key={slide.id}
                      id={slide.id}
                      style={{
                        width: imageWidth(
                          slide.width / slide.height,
                          containerRatio
                        ),
                        paddingBottom: imageHeight(
                          slide.width / slide.height,
                          containerRatio
                        ),
                      }}
                    >
                      {/*
                      {!!slide.video && <StreamContainer><Stream controls autoplay src={slide.video.secret} width="100%" height="100%" /></StreamContainer>}
                      {!!slide.videoUrl && (
                        <Vimeo
                          videoLink={slide.videoUrl}
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                          }}
                        />
                      )
                      } */}

                      {(!!slide.video || !!slide.videoUrl) && (
                        <VideoPlayer
                          url={
                            !!slide.video
                              ? videoUrl(slide.video)
                              : slide.videoUrl
                          }
                          width="100%"
                          height="100%"
                          playing
                          loop
                          style={{
                            backgroundColor: "white",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                        />
                      )}

                      {!slide.video && !slide.videoUrl && (
                        <Delay wait={30 + index * 220}>
                          <SlideImage slide={slide} />
                        </Delay>
                      )}
                    </ImageContainer>
                    {position === "side-by-side" &&
                      index < slides.length - 1 && (
                        <ImageContainer
                          id={slides[index + 1].id}
                          style={{
                            width: imageWidth(
                              slides[index + 1].width /
                                slides[index + 1].height,
                              containerRatio
                            ),
                            paddingBottom: imageHeight(
                              slides[index + 1].width /
                                slides[index + 1].height,
                              containerRatio
                            ),
                          }}
                        >
                          <Delay wait={30 + index * 220}>
                            <SlideImage slide={slides[index + 1]} />
                          </Delay>
                        </ImageContainer>
                      )}
                  </Slide>
                </div>
              </Center>
            );
          })}

      {!isWide &&
        slides.map((slide) => (
          <>
            <ImageContainer
              my={[3, 4, 4]}
              key={slide.id}
              style={{
                width: "100%",
                paddingBottom: imageHeight(
                  slide.width / slide.height,
                  slide.width / slide.height
                ),
              }}
            >
              {/* 
            {!!slide.video && <StreamContainer><Stream controls autoplay src={slide.video.secret} width="100%" height="100%"/></StreamContainer>}
            {!!slide.videoUrl &&
              <Vimeo
                videoLink={slide.videoUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                }}
              />
            }*/}

              {(!!slide.video || !!slide.videoUrl) && (
                <VideoPlayer
                  url={!!slide.video ? videoUrl(slide.video) : slide.videoUrl}
                  width="100%"
                  height="100%"
                  playing
                  loop
                />
              )}

              {!slide.video && !slide.videoUrl && <SlideImage slide={slide} />}
            </ImageContainer>
          </>
        ))}
    </Container>
  );
};
