import jsSHA from "jssha"

// https://images.weserv.nl/?url=[urlNoProtocol]&w=[width]
// http://edge.zimage.io/?url=[url]&w=[width]
// https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?url=[url]&container=focus&resize_w=[width]

// Helper for URL generator
function hex2a(hexx) {
  var hex = hexx.toString() //force conversion
  var str = ""
  for (var i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  return str
}

// URL generator logic
function generateProxyUrl(opts) {
  var encoded_url = window
    .btoa(opts.url)
    .replace(/=/g, "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-")

  var path =
    "/" +
    opts.resize +
    "/" +
    opts.width +
    "/" +
    opts.height +
    "/" +
    opts.gravity +
    "/" +
    opts.enlarge +
    "/" +
    encoded_url +
    "." +
    opts.extension
  var shaObj = new jsSHA("SHA-256", "BYTES")
  shaObj.setHMACKey(opts.key, "HEX")
  shaObj.update(hex2a(opts.salt))
  shaObj.update(path)
  var hmac = shaObj
    .getHMAC("B64")
    .replace(/=/g, "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-")
  return opts.proxy_url + "/" + hmac + path
}

export const composedUrl = (_imageProxies, _url, width, height, secret) => {
  const url = "s3://ws-img/" + secret
  return generateProxyUrl({
    url,
    resize: "fit",
    enlarge: "0",
    gravity: "no",
    width,
    height,
    extension: "jpg",
    key:
      "5958c65fea7503788768bff8e0e58db3febe975a0e1e96dbee82fb33a3a5a07bd7fd095749a44b3d75c65a74b66e22cec48aac46a965507b084679a82b0e50b4",
    salt:
      "87f7678f075b1b545ae88adc134421074adf54f0e777326c9839d4ed1a730eaee0296f8e641f25b066637fa043c212fecf7d5e20e432c223f0d4fac60542ab83",
    proxy_url: "https://img.whitespace.app",
  })
}

export const surfaces = [
  320 * 240,
  640 * 480,
  960 * 1280,
  1024 * 1420,
  1536 * 2048,
]

// later ook compressie erbij: hidpi: hogere compressie
const optimalSurface = (canvas, surfaces, pixelRatio) => {
  const canvasSurface = canvas.width * pixelRatio * (canvas.height * pixelRatio)
  for (let i = 0; i < surfaces.length; i++) {
    // the first surface that's bigger, times a ratio, give me that one
    if (surfaces[i] > canvasSurface * 1.1) {
      return surfaces[i]
    }
  }
  // last resort
  return surfaces[surfaces.length - 1]
  // return dimensions: width and height
}

const dimensionsToServe = (canvas) => {
  const pixelRatio = getDevicePixelRatio()
  const surface = optimalSurface(canvas, surfaces, pixelRatio)

  const imageRatio = canvas.width / canvas.height
  let width = imageRatio * Math.sqrt(surface / imageRatio)
  const height = Math.round(width / imageRatio)
  width = Math.round(width)
  return {
    width,
    height,
  }
}

export default dimensionsToServe

const getDevicePixelRatio = () => {
  if (window.devicePixelRatio) {
    return window.devicePixelRatio
  } else {
    return 1
  }
}

export const getPixelRatio = () => {
  var pixelRatio = getDevicePixelRatio()
  var screenWidth = window.screen.width
  var screenHeight = window.screen.height
  if (window.matchMedia) {
    if (
      window.matchMedia("(orientation: landscape)").matches &&
      screenWidth < screenHeight
    ) {
      screenHeight = [screenWidth, (screenWidth = screenHeight)][0]
    }
  }
  if (screenWidth < window.innerWidth) {
    pixelRatio = pixelRatio * (screenWidth / window.innerWidth)
  }
  // it's fine to load a 1024 image in a canvas of 520 pixels on a retina screen you see:
  // on a hidpi screen a slightly smaller image is acceptable: (losse functie van maken, rite!)
  pixelRatio = 1 + (pixelRatio - 1) * 0.75
  return pixelRatio
}

export const fit = (image, canvas) => {
  const canvas_ratio = canvas.width / canvas.height
  const image_ratio = image.width / image.height
  if (canvas_ratio > image_ratio) {
    var resulting_width = Math.round(canvas.height * image_ratio)
    return { width: resulting_width, height: canvas.height }
  } else {
    var resulting_height = Math.round(canvas.width / image_ratio)
    return { width: canvas.width, height: resulting_height }
  }
}
