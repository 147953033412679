import { gql } from "@apollo/client"

const ALL_ALBUMS = gql`
  query allAlbums($username: String!) {
    userByUsername(username: $username) {
      albums(orderBy: POS_ASC, filter: { hidden: { equalTo: false } }) {
        id
        title
        slug
        description
        keyValues {
          id
          name
          value
        }
        tags {
          id
          name
        }
        slidesConnection {
          totalCount
        }
        slides(orderBy: POS_ASC, first: 1) {
          id
          image {
            id
            secret
          }
          video {
            id
            secret
          }
          videoUrl
          width
          height
          title
          description
        }
      }
    }
  }
`

export default ALL_ALBUMS
