import * as React from "react"

const LogoSymbol = ({ size = 8, style }) => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{ width: size + "%", ...style }}
		viewBox="0 0 30 117.5" space="preserve">
		<g transform="translate(-184.7 -442)">
			<path fill="currentColor" d="M185,442.33v4.8l25.25,0.01v51.12H185v4.8h25.25v24H185v4.8h25.25v22.33H185v4.8l30,0.01l-0.01-116.66
		L185,442.33z"/>
		</g>
	</svg>
)

export default LogoSymbol