import * as React from "react";
import { View } from "./";
import useRect from "../utilities/useRect";
import { useAtom } from "jotai";
import { contentArea } from "../Home";
import { useNavigate } from "react-router-dom";

export const GalleryArea = ({ children, setRect = false }) => {
  const ref = React.useRef(null);
  const rect = useRect(ref);

  const [, setContentArea] = useAtom(contentArea);
  console.log({ rect });
  const sectionWidth = 100;
  const sectionHeight = 100;
  const contentAreaHeight = 60;
  const contentAreaWidth = rect.height * (550 / 670) * 0.6;

  React.useEffect(() => {
    if (setRect) {
      const halfWidth = rect.width / 2;
      const contentAreaWidth = rect.height * (550 / 670) * 0.6;

      const middleSpace = halfWidth - contentAreaWidth;

      const totalContentArea =
        contentAreaWidth + middleSpace + contentAreaWidth;

      setContentArea({
        height: rect.height * 0.6,
        width: totalContentArea,
        top: rect.top + rect.height * 0.2,
        space: middleSpace,
        contentWidth: contentAreaWidth,
      });
    }
  }, [rect, setContentArea, setRect]);

  return (
    <View
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: `${sectionWidth}%`,
        height: `${sectionHeight}%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={ref}
    >
      <View
        style={{
          position: "relative",
          width: `${contentAreaWidth}`,
          height: `${contentAreaHeight}%`,
        }}
      >
        {children}
      </View>
    </View>
  );
};

const SectionArea = ({
  style,
  children,
  bg = "secondaryBackground",
  className,
}) => {
  const ref = React.useRef(null);
  const rect = useRect(ref);

  const sectionWidth = 50;
  const sectionHeight = 100;
  const contentAreaHeight = 60;
  const contentAreaWidth = rect.height * (550 / 670) * 0.6;

  // width.. wordt dus een absoluut getal..
  const navigate = useNavigate();

  // center area which is 60vh... width.. 60*(550*670)
  return (
    <View
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: `${sectionWidth}%`,
        height: `${sectionHeight}%`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "left 550ms ease-in-out",
        ...style,
      }}
      className={className}
      bg={bg}
      ref={ref}
      onClick={() => navigate("/", { replace: true })}
    >
      <View
        onClick={(e) => {
          e.stopPropagation();
          console.log("carousel click");
        }}
        style={{
          position: "relative",
          width: contentAreaWidth,
          height: `${contentAreaHeight}%`,
        }}
      >
        {children}
      </View>
    </View>
  );
};

export default SectionArea;
