import * as React from "react"
import Div100vh from 'react-div-100vh'
import styled, { keyframes, createGlobalStyle } from "styled-components"
import View from "./View"
import { useWindowSize } from 'react-use';
import VideoPlayer from "./VideoPlayer";
import { videoUrl } from "./CoverVideo"
import { Link } from "react-router-dom";
import getKeyValue from "../utilities/getKeyValue"
import Inline from "./Inline"
import Text from "./Text"

const Black = createGlobalStyle`
  body {
    background-color: black;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled(View).attrs(() => ({ as: Div100vh, bg: "black" }))`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9871;
  opacity: 0;
  animation: ${fadeIn} 350ms ease-out 0s 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0ms;
`

const Scroll = styled(View)`
  position: absolute;
  width: 0;
`

const VideoWrapper = styled(View).attrs(() => ({ bg: "black" }))`
  position: relative;

  ${({ rotate, height }) => rotate && `
  
  position: relative;

  transform-origin: 0 0;

  transform: rotate(90deg);

  left: ${height}px;
  
  `}
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`

const Close = styled(View).attrs(() => ({ as: Link, color: "white", to: "../film", p: 2 }))`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 987;
`

const Specs = styled(View)`
  position: absolute;
  left: 28px;
  top: 24px;
`

export function iPhone() {
  return [
    'iPhone Simulator',
    'iPod Simulator',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
}

const FullMobileVideo = ({ album }) => {

  const { width, height } = useWindowSize();
  const rotate = height > width

  const slide = album?.slides[0] ?? null

  const isIphone = iPhone()

  const resultingHeight = rotate ? width : isIphone ? window.screen.width : height

  return (
    <>
      <Scroll style={{ height: resultingHeight + 200 }} />
      <Container style={{ height: resultingHeight }}>
        <Black />
        <VideoWrapper rotate={rotate} width={rotate ? height : width} height={resultingHeight}>
          {(!!slide.video || !!slide.videoUrl) && <VideoPlayer isPhone url={!!slide.video ? videoUrl(slide.video) : slide.videoUrl} width="100%" height="100%" playing loop >
            <Close>
              <svg width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </Close>
            <Specs>
              <Inline key={album.id} style={{ textDecoration: "none" }} mt={3} mb={2} color="white" hover>
                <Text
                  block
                  color="white"
                >
                  {!!getKeyValue(album, "Client") && `${getKeyValue(album, "Client").toUpperCase()}`}
                </Text>
                <Text textStyle={!!getKeyValue(album, "Client") ? "more" : "body"}>{!!getKeyValue(album, "Client") && "‎ / "}{album.title}</Text>
              </Inline>
              {!!getKeyValue(album, "Director") && <Text textStyle="more" color="white">Director : {getKeyValue(album, "Director")}</Text>}
            </Specs>
          </VideoPlayer>}
        </VideoWrapper>
      </Container >
    </>
  )
}

export default FullMobileVideo