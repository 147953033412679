// component that displays the meta data...
import * as React from "react";
import { Text, View } from "./";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";
import { contentArea } from "../Home";

const TextContent = styled(Text).attrs(() => ({
  textStyle: "body",
  color: "text",
}))`
  text-transform: uppercase;
  position: absolute;
  bottom: -20%;
  font-feature-settings: "kern" 1, "liga" 1, "tnum" 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  text-decoration: none;
  white-space: nowrap;
`;

const Content = styled(View)`
  display: flex;
  position: absolute;
  bottom: -20%;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  font-feature-settings: "kern" 1, "liga" 1, "tnum" 1;
  white-space: nowrap;
`;

export const metaData = atom({ left: null, right: null, center: null });

const Meta = ({ left, right, center }) => {
  const [data] = useAtom(metaData);
  const [rect] = useAtom(contentArea);
  const halfCenterSpace = rect ? rect.space / 2 : 0;
  const location = useLocation();
  const navigate = useNavigate();
  console.log({ center });
  if (center) {
    return (
      <Content
        style={{
          left: `calc(100% + ${halfCenterSpace}px)`,
          transform: "translateX(-50%)",
        }}
      >
        {data?.center?.client && (
          <Text inline>{data?.center?.client}‎ / ‎</Text>
        )}
        {data?.center?.title && (
          <Text textStyle="more" inline>
            {data?.center?.title}
          </Text>
        )}
        {data?.center?.director && (
          <Text inline textStyle="more">
            ‎ / ‎Director: {data?.center?.director}
          </Text>
        )}
      </Content>
    );
  }
  if (left) {
    return (
      <Content style={{ left: 0 }}>
        {data?.left?.title && <Text inline>{data?.left?.title}‎‎</Text>}
        {data?.left?.client && (
          <Text textStyle="more" inline>
            ‎ / ‎{data?.left?.client}
          </Text>
        )}
      </Content>
    );
  }
  return (
    <TextContent
      {...(right &&
        data.right === "INDEX" && {
          as: Link,
          to: location.pathname.includes("/index")
            ? location.pathname.replace("/index", "")
            : location.pathname + "/index",
          onClick: (e) => {
            if (location.pathname.includes("/index")) {
              console.log("history back");
              e.preventDefault();
              navigate(location.pathname.replace("/index", ""), {
                replace: true,
              });
            }
          },
          replace: true,
        })}
      style={
        left
          ? { left: 0 }
          : right
          ? { right: 0 }
          : {
              left: `calc(100% + ${halfCenterSpace}px)`,
              transform: "translateX(-50%)",
            }
      }
    >
      {left && data.left}
      {right && data.right}
    </TextContent>
  );
};

export default Meta;
