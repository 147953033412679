import React from "react"
import styled from "styled-components"
import { View } from "./"

const RatioContainer = styled(View)`
  position: relative;
  height: 0;
  padding-bottom: ${props => (props.ratio ? 100 / props.ratio : 33)}%;
  overflow-y: visible;
`

export default RatioContainer
