import * as React from "react";
import styled from "styled-components";
import { Stack, Text } from "./components";

const Container = styled(Stack)`
  text-decoration: none;
  a {
    color: inherit;
    text-decoration: none;
    transition: color 250ms ease;
  }
  a:hover {
    color: black;
  }
`;

const ContactDetails = () => (
  <Container gap={5} color="secondary">
    <Text>REPRESENTED BY</Text>

    <Stack gap={3}>
      <Text my={5}>LUX Artists Ltd.</Text>
      <Stack gap={2}>
        <Text block as="a" href="tel:00442076379064" hover textStyle="more">
          +44 (0)20 7637 9064
        </Text>
        <Text block as="a" href="tel:0033633406143" hover textStyle="more">
          +33 (0)63 340 6143
        </Text>
      </Stack>
      <Stack gap={2}>
        <Text block textStyle="more" mt={3}>
          Features /
        </Text>
        <Text
          block
          as="a"
          href="mailto:rebecca@luxartists.net"
          hover
          textStyle="more"
        >
          Rebecca Fayyad Palud / rebecca@luxartists.net
        </Text>
        <Text
          block
          as="a"
          href="mailto:tessa@luxartists.net"
          hover
          textStyle="more"
        >
          Tessa Williams / tessa@luxartists.net
        </Text>
      </Stack>
      <Stack gap={2}>
        <Text block textStyle="more">
          Commercials / Music-Video /
        </Text>
        <Text
          block
          as="a"
          href="mailto:jerry@luxartists.net"
          hover
          textStyle="more"
        >
          Jerry Deeney / jerry@luxartists.net
        </Text>
        <Text
          block
          as="a"
          href="mailto:nikhil@luxartists.net"
          hover
          textStyle="more"
        >
          Nikhil Sinh Smith / nikhil@luxartists.net
        </Text>
        <Text
          block
          as="a"
          href="mailto:wladimir@luxartists.net"
          hover
          textStyle="more"
        >
          Wladimir Baseden / wladimir@luxartists.net
        </Text>
      </Stack>

      <Text my={5}>US / CREATIVE ARTISTS AGENCY</Text>
      <Text block as="a" href="tel:0014242882000" hover textStyle="more">
        +1 (0)42 4288 2000
      </Text>
      <Stack gap={2}>
        <Text block textStyle="more">
          Commercials /
        </Text>
        <Text
          block
          as="a"
          href="mailto:erin.randol@caa.com"
          hover
          textStyle="more"
        >
          Erin Randol / erin.randol@caa.com
        </Text>
        <Text
          block
          as="a"
          href="mailto:hilary.mcquaide@caa.com"
          hover
          textStyle="more"
        >
          Hilary McQuaide / hilary.mcquaide@caa.com
        </Text>
      </Stack>
      <Stack gap={2}>
        <Text block textStyle="more">
          Features /
        </Text>
        <Text
          block
          as="a"
          href="mailto:ann.murphy@caa.com"
          hover
          textStyle="more"
        >
          Ann Murphy / ann.murphy@caa.com
        </Text>
      </Stack>
    </Stack>

    <Text>
      <a
        href="https://www.instagram.com/daniellucasbouquet"
        target="_blank"
        rel="noopener noreferrer"
      >
        INSTAGRAM
      </a>{" "}
      /{" "}
      <a
        href="https://vimeo.com/danielbouquet"
        target="_blank"
        rel="noreferrer"
      >
        VIMEO
      </a>
    </Text>
    <Text
      block
      as="a"
      href="mailto:studio@danielbouquet.com"
      hover
      textStyle="more"
    >
      studio@danielbouquet.com
    </Text>
  </Container>
);

export default ContactDetails;
