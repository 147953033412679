import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-use";

const ManageScroll = () => {
  const location = useLocation();
  const scrollPositions = useRef({});
  const [scrollY, setScrollY] = useState(0);
  const key = location.state ? location.state.key : "no-key";
  const locationPreviousRef = useRef();

  useEffect(() => {
    const newScrollPositions = {
      ...scrollPositions.current,
      [key]: window.scrollY,
    };
    try {
      window.sessionStorage.setItem(
        "scrollPositions",
        JSON.stringify(newScrollPositions)
      );
    } catch (e) {}
    scrollPositions.current = { ...newScrollPositions };
  }, [key, scrollY]);

  const listener = () => {
    setScrollY(window.scrollY);
  };

  useLayoutEffect(() => {
    if (
      locationPreviousRef.current?.includes("/index") ||
      location.pathname.includes("/index")
    )
      return;

    if (scrollPositions.current[key]) {
      window.scrollTo(0, scrollPositions.current[key]);
    } else {
      window.scrollTo(0, 0);
    }
  }, [key, location.pathname]);

  useEffect(() => {
    let storage = window.sessionStorage.getItem("scrollPositions");
    if (storage) {
      scrollPositions.current = JSON.parse(storage);
      /*
      if (scrollPositions[key]) {
        window.scrollTo(0, scrollPositions[key]);
      }
      */
    }
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  useEffect(() => {
    locationPreviousRef.current = location.pathname;
  }, [location]);
  return null;
};

export default ManageScroll;
