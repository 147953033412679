import React from "react"
import styled from "styled-components"
import { View, Image as SlideImage, Text, RatioContainer } from "./"
import Inline from "./Inline"
import ScrollToTop from "./ScrollToTop"
import CoverVideo, { videoUrl } from "./CoverVideo"
import getKeyValue from "../utilities/getKeyValue"
import { Link } from "react-router-dom"
import LazyLoad from 'react-lazyload';

const Line = styled(View)`
  position: relative;
  border-bottom: 1px solid black;
  pointer-events: none;
`

const Item = ({ album, slide, title }) => (
  <View mb={8} as={Link} block to={"/" + album.slug} style={{ textDecoration: "none" }}>
    <RatioContainer
      mb={5}
      key={slide.id}
      style={{
        width: "100%"
      }}
      ratio={4 / 5}
      bg="#DDD"
    >
      {(!!slide.video || !!slide.videoUrl) && <LazyLoad once><CoverVideo url={!!slide.video ? videoUrl(slide.video) : slide.videoUrl} width="100%" height="100%" playing loop /></LazyLoad>

      }

      {!slide.video && !slide.videoUrl && (
        <LazyLoad once><SlideImage slide={slide} /></LazyLoad>)}

    </RatioContainer>

    {(!!slide.video || !!slide.videoUrl) ?

      <>
        <Inline>
          {!!getKeyValue(album, "Client") && <Text mt={2} color="text">{getKeyValue(album, "Client")} / ‎</Text>}
          <Text color="text" textStyle="more">
            {title}
          </Text>
        </Inline>
        {!!getKeyValue(album, "Director") && <Text mt={2} color="text" textStyle="more">Director : {getKeyValue(album, "Director")}</Text>}

      </>
      :

      <>
        <Text color="text">
          {title} /
        </Text>
        {!!getKeyValue(album, "Director") && <Text mt={2} color="text" textStyle="more">Director : {getKeyValue(album, "Director")}</Text>}
        {!!getKeyValue(album, "Client") && <Text mt={2} color="text" textStyle="more">{getKeyValue(album, "Client")}</Text>}
      </>
    }
  </View>
)

const MobileAlbumsOverview = ({ albums, where }) => {

  return <View bg="secondaryBackground" pt={2} pb={3} style={{ minHeight: "120vh" }} px={6}>
    <ScrollToTop />
    <Inline mt={14} gap={0} mb={10}><Text
      style={{ position: "relative", top: "-0.33em", marginRight: "auto", marginLeft: 0 }}
      color="primary"
      pr={6}
      pl={0}
    >
      {where}
    </Text>
      <Line flex style={{ top: "-0.63rem" }} /></Inline>

    <View style={{ position: "relative", top: "-0.33em", }}>{albums?.map(album => <Item album={album} slide={album.slides?.[0]} title={album.title} key={album.id} />)}</View>
  </View>
}

export default MobileAlbumsOverview