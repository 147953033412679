import React, { Fragment } from "react"
import { View } from "./"

const Stack = ({ children, gap = 2, ...rest }) => {
  const Wrapper = Object.keys(rest).length > 0 ? View : Fragment
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (!child) return null
    if (index === 0 || index === children.length) {
      //return child
    }
    const addProps =
      typeof child.props.m !== "undefined" ||
      typeof child.props.my !== "undefined"
        ? null
        : {
            mt:
              (typeof child.props.mt !== "undefined" && child.props.mt) || gap,
            mb:
              (typeof child.props.mb !== "undefined" && child.props.mb) ||
              (index < children.length - 1 && gap),
          }
    return React.cloneElement(child, addProps)
  })
  return <Wrapper {...rest}>{childrenWithProps}</Wrapper>
}

export default Stack
