import * as React from "react"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API,
  cache: new InMemoryCache(),
})

const DataProvider = (props) => <ApolloProvider client={client} {...props} />

export default DataProvider
