import * as React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Inline, Text, View } from "./components"
import { clean } from "./components/Clean"
import LogoType from "./components/LogoType"
import MenuIcon from "./components/MenuIcon"
import Stack from "./components/Stack"
import { useWindowSize } from 'react-use';
import LogoSymbol from "./components/LogoSymbol"
import getKeyValue from "./utilities/getKeyValue"
import ContactDetails from "./ContactDetails"
import { useLocation } from "react-router-dom"

function use100vh() {
  const ref = React.useRef();
  const { height } = useWindowSize();

  React.useEffect(
    () => {
      if (!ref.current) {
        return;
      }
      ref.current.style.height = height + 'px';
    },
    [height],
  );

  return [ref, height];
}

const HeaderContainer = styled(View).attrs(() => ({
  bg: "secondaryBackground",
  px: 6,
  py: 6,
}))`
  position: sticky;
  top: 0;
  z-index: 900;
`

const MenuContainer = styled(View)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1290;
`

const StickyNavigation = styled(View).attrs(() => ({ bg: "secondaryBackground", pb: 3, pt: 4 }))`
  position: sticky;
  top: 5.57rem;
  z-index: 889;
`

const Content = styled(View).attrs(() => ({ bg: "secondaryBackground", pt: 10 }))`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  transition: height 250ms ease-in;
  @media(max-height: 550px) {
    position: absolute;
    min-height: 700px;
    z-index: 0;
    ${props => !!props.scroll && `
      height: 0;
      min-height: 0;
      visibility: hidden;
    `}
  }
`

const Line = styled(View)`
  position: absolute;
  border-bottom: 1px solid black;
  width: 100vw;
  pointer-events: none;
`

const AbsoluteAndCenter = styled(View).attrs(() => ({ color: "white" }))`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const MobileHeader = ({ photographyAlbums, filmAlbums, children, wereLast }) => {
  const [menuActive, setMenuActive] = React.useState(false)
  const [where, setWhere] = React.useState(null)

  // const height = use100vh()
  const [ref, height] = use100vh();

  const location = useLocation()

  return (<>
    <MenuContainer bg="secondaryBackground" {...menuActive && { style: { height } }}>

      <HeaderContainer>
        <MenuIcon
          relative
          active={menuActive}
          onClick={() => setMenuActive((active) => !active)}
        />
        <LogoType relative onClick={() => setMenuActive(false)} />
      </HeaderContainer>

      {menuActive && (
        <>
          <View px={6} pb={8}>

            <StickyNavigation>
              <Stack gap={3}>
                <Text block onClick={() => setWhere("film")}  {...where === "film" && { as: Link, to: "/film", onClick: () => setMenuActive(false) }} hover pointer color={where === "film" ? "primary" : "secondary"}>
                  FILM
          </Text>

                <Text block onClick={() => setWhere("photography")}  {...where === "photography" && { as: Link, to: "/photography", onClick: () => setMenuActive(false) }} hover pointer color={where === "photography" ? "primary" : "secondary"}>
                  PHOTOGRAPHY
          </Text>

                <Text onClick={() => setWhere("contact")} hover pointer mb={8} color={where === "contact" ? "primary" : "secondary"}>
                  CONTACT
          </Text>
              </Stack>
            </StickyNavigation>
            <Stack gap={3}>
              {where === "photography" &&
                photographyAlbums?.map((album) => (
                  <Stack
                    gap={1}
                    my={4}
                    as={clean(Link)}
                    key={album.id}
                    _to={album.slug}
                    style={{ textDecoration: "none" }}
                    color={location.pathname.includes(album.slug) ? "primary" : "secondary"}
                    onClick={() => setMenuActive(false)}
                    block
                  >
                    <Text>
                      {album.title}{"‎ / "}{!getKeyValue(album, "Client") && <div style={{ float: "right" }}>:</div>}
                    </Text>
                    {!!getKeyValue(album, "Client") && <Text textStyle="more" color="inherit" flex>{getKeyValue(album, "Client")}<div style={{ float: "right" }}>:</div></Text>}
                  </Stack>
                ))}

              {where === "film" &&
                filmAlbums?.map((album) => (
                  <Stack mt={3} mb={4} key={album.id} gap={1} as={clean(Link)} style={{ textDecoration: "none" }} _to={album.slug} color={location.pathname.includes(album.slug) ? "primary" : "secondary"}
                    hover block
                    onClick={() => setMenuActive(false)}
                  >



                    <Text
                      block
                      color={"secondary"}
                    >
                      {!!getKeyValue(album, "Client") && `${getKeyValue(album, "Client").toUpperCase()} /`}
                    </Text>
                    <Text textStyle={!!getKeyValue(album, "Client") ? "more" : "body"}>{album.title}</Text>



                  </Stack>
                ))}

              {where === "contact" && <ContactDetails />}
            </Stack>
          </View>
        </>
      )}
    </MenuContainer>

    <Content ref={ref} mx={6} scroll={["film", "photography", "contact"].includes(wereLast)}>

      <Text
        as={Link}
        to="film"
        style={{ position: "relative", top: "0.01em", marginRight: "auto", marginLeft: 0 }}
        color="primary"
        pr={6}
        mt={14}
        mb={10}
      >
        FILM
        <Line style={{ left: "100%", top: "48%" }} />
      </Text>

      <View style={{ flex: 1, overflowY: "hidden" }} relative>
        {children}
        <AbsoluteAndCenter><LogoSymbol size={11} style={{ minWidth: 16, maxWidth: 28 }} /></AbsoluteAndCenter>
      </View>

      <Text
        as={Link}
        to="photography"
        style={{ position: "relative", marginRight: 0, marginLeft: "auto" }}
        color="primary"
        pl={6}
        my={10}
      >
        PHOTOGRAPHY
        <Line style={{ right: "100%", bottom: "48%" }} />
      </Text>

    </Content>



  </>
  )
}

export default MobileHeader
