import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import historyLength from "../utilities/historyLength";

import { View } from "./";

const Circle = styled(View)`
  width: 0.6rem;
  height: 0.6rem;
  border: 1px solid black;
  border-radius: 0.8rem;
  transition: background-color 240ms ease-in-out;
`;

const Container = styled(View)`
  position: absolute;
  right: -5px;
  top: calc(-20% - 5px);
  padding: 5px;
  ${({ relative }) =>
    relative &&
    `
    position: relative;
    float: right;
    top: -5px;
  `}
  :hover ${Circle} {
    background-color: ${({ active }) => (active ? "transparent" : "black")};
  }
`;

const MenuIcon = ({ active, home, relative, onClick, open }) => {
  return (
    <Container
      {...{
        to: active ? "../" : "/contact",
        replace: !home ? true : false,
        style: { cursor: "pointer", pointerEvents: "auto" },
        as: Link,
        onClick: (e) => {
          if (home) return;
          if (!!onClick) {
            onClick();
          } else if (historyLength() > 0 && !!active) {
            console.log("history back");
            e.preventDefault();
            window.history.back();
          }
        },
      }}
      relative={relative}
      id="menu-button"
    >
      <Circle
        active={active}
        bg={active && !open ? "primary" : "transparent"}
      />
    </Container>
  );
};

export default MenuIcon;
