import * as React from "react"
import { createGlobalStyle, keyframes } from "styled-components"
import ThemeProvider from "./components/ThemeProvider"
import DataProvider from "./components/DataProvider"
import { Provider as JotaiProvider } from "jotai"
import reset from "styled-reset"
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import Project from "./Project"

import { Clean } from "./components"
import { clean } from "./components/Clean"

import Home from "./Home"
import ManageScroll from "./components/ManageScroll"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 12px;
    font-size: clamp(11px, 1.15vw, 13px);
  }

  @media (max-width: 800px) {
    html {
      font-size: 13px;
    }
  }

  body {
    opacity: 0;
    animation: ${fadeIn} 1s ease-out 0s 1;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 350ms;
  }
`

// verhouding van scherm, groter dan 16/9 ? breedte max hoogte * 160

// THREE SECTION: CarouselSection, MenuSection, another CarouselSection -> category="film"
// SectionArea.. een component die de schaal, witruimte voor z'n rekening neemt.
// ze staan absoluut op de pagina, gepositioneert op percentage
// op basis van de url bepaal ik de positie..

// zijn drie scenario's: lekker schalen,

const Test = ({ text, ...rest }) => <section {...rest}>{text}</section>
// const CleanTest = (props) => <Clean as={Test} {...props} />
// const CleanHOC = clean(Test)

const App = () => (
  <JotaiProvider>
    <DataProvider>
      <ThemeProvider>
        <BrowserRouter>
          <GlobalStyle />
          <ManageScroll />
          <Routes>
            <Route
              path="/*"
              element={
                <Home>
                  <Outlet />
                </Home>
              }
            >
              <Route path="film" element={null} />
              <Route path="photography" element={null} />
              <Route path="contact" element={null} />
              <Route path=":slug/*" element={<Project />} />
            </Route>
          </Routes>

          {/* 
      <div>Hi, I'm an app!</div>
      <Text _data-banana="dadadie" p={[3, 5, 8]}>
        Test some text
      </Text>
      <Text
        as={Test}
        text="passed to 'as' Component, props enter the DOM"
        p={4}
        bg="lightblue"
      />
      <Text
        as={CleanTest}
        _text="Cleaned.. so the DOM is clean"
        p={4}
        bg="lightblue"
      />
      <Text as={CleanHOC} _text="cleaned with HOC" p={4} bg="lightblue" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="users/*" element={<Users />} />
        </Routes>
      </BrowserRouter>
      */}
        </BrowserRouter>
      </ThemeProvider>
    </DataProvider>
  </JotaiProvider>
)

export default App