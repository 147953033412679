import * as React from "react"
import { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { Routes, Route, useParams, Link, Navigate } from "react-router-dom"
import { View, Text, Stack, Inline } from "./components"
import Gallery from "./components/Gallery"
import { useQuery } from "@apollo/client"
import ALBUM from "./queries/ALBUM"
import { contentArea } from "./Home"
import { useAtom } from "jotai"
import MenuIcon from "./components/MenuIcon"
import { useMedia } from "use-media"
import AlbumIndex from "./components/AlbumIndex"
import FullMobileVideo from "./components/FullMobileVideo"
import { useWindowSize } from "react-use"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const FadeIn = styled(View)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 0s 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 350ms;
`

const StickyTitle = styled(Stack).attrs(() => ({ bg: "secondaryBackground", pt: 1, pb: 6, gap: 2 }))`
  position: sticky;
  top: 4.65rem;
  z-index: 765;
  outline: 2px solid ${({ theme }) => theme.colors.secondaryBackground};
`

function n(n) {
  return n > 9 ? "" + n : "0" + n;
}

const checkIsVideo = album => album?.slidesConnection?.totalCount < 3

export function iPhone() {
  return [
    'iPhone Simulator',
    'iPod Simulator',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
}

const Project = () => {
  const { slug } = useParams()

  const { data } = useQuery(ALBUM, {
    variables: { username: process.env.REACT_APP_WHITESPACE_USER, slug },
  })

  const album = data?.userByUsername?.albums?.[0] ?? null

  // index
  const [rect] = useAtom(contentArea)

  const { width, height } = useWindowSize();
  const maxScreenSize = Math.max(width, height)

  const fullVideo = maxScreenSize < 768 || iPhone() // useMedia({ maxWidth: 800 })

  const mobile = useMedia({ maxWidth: 800 })

  const mobileGallery = width < 640

  useEffect(() => {
    if (!!data) window.scrollTo(0, 0)
  }, [data])

  const isVideo = checkIsVideo(album)

  if (!data) {
    return <View px={6} pb={6} pt={10} style={{ minHeight: "100vh" }} />
  }

  if (!isVideo && mobileGallery) {
    return (
      <View px={6} pb={6} pt={10} style={{ minHeight: "100vh" }}>
        {data && !isVideo && (
          <><StickyTitle><Inline><Text flex>{album.title}</Text><Text pointer hover style={{ letterSpacing: "-0.1em" }} onClick={() => window.history.back()} replace>––</Text></Inline>
            <Text textStyle="more">Images : {n(album.slidesConnection.totalCount)}</Text></StickyTitle>
            <Gallery album={album} isWide={!mobileGallery} /></>
        )}
      </View>
    )
  }

  if (isVideo && fullVideo) {
    return (
      <FullMobileVideo album={album} />
    )
  }

  return (
    <>
      {data && mobile && <View bg="secondaryBackground" px={6} pb={6} pt={10}>
        <StickyTitle>
          <Text>{album.title}</Text>
          <Text textStyle="more">Images : {n(album.slidesConnection.totalCount)}</Text>
        </StickyTitle>
      </View>}
      <FadeIn bg="secondaryBackground" style={{ minHeight: "100vh" }}>
        {data && album && <View px={6}><Gallery album={album} isWide /></View>}
        <Routes>
          <Route
            path="index"
            element={
              <View
                style={{
                  position: "fixed",
                  left: "50%",
                  top: 0,
                  width: "50%",
                  height: "100vh",
                  zIndex: 201,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                bg="background"
              >
                <View
                  style={{
                    width: rect?.contentWidth,
                    height: rect?.height,
                    position: "relative",
                  }}
                >
                  <MenuIcon active={true} />
                  <AlbumIndex album={album} />
                </View>
              </View>
            }
          />
        </Routes>
      </FadeIn>
    </>
  )
}

export default Project
