import * as React from "react"
import styled from "styled-components"
import textStyles from "../utilities/textStyles"
import { space, color, fontSize, fontWeight } from "styled-system"
import Clean from "./Clean"

const Text = styled(Clean)`
  ${(props) => (_) => textStyles(props.textStyle || "body")};
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${(props) => (props.center ? `text-align: center` : "")};
  ${(props) => (props.inline ? `display: inline` : "")};
  ${(props) => (props.block ? `display: block` : "")};
  ${(props) => (props.pointer ? `cursor: pointer` : "")};

  transition: color 250ms ease;

  ${({ hover }) =>
    hover === true &&
    `
    :hover {
      color: black;
    }
  `};
  text-decoration: none;
`

export default Text
