export const colors = {
  primary: "#000000",
  primaryHover: "#666666",
  primaryText: "#FFFFFF",
  secondary: "#777777",
  secondaryHover: "#555555",
  secondaryText: "#888888",
  alternate: "#737373",
  alternateHover: "#A6A6A6",
  alternateText: "rgb(168, 166, 190)",
  accent: "rgb(248, 248, 248)",
  secondaryAccent: "rgb(227, 244, 254)",
  alternateAccent: "rgb(70, 64, 88)",
  text: "#000000",
  textInvert: "#FFFFFF",
  textPlaceholder: "#DDDDDD",
  background: "#FFFFFF",
  secondaryBackground: "#F0F0F0",
  alternateBackground: "#E6DCDC",
  backgroundInvert: "#000000",
  overlay: "#61618A",
  border: "#000000",
  disabled: "#D9D9D9",
  shadow: "#000000",
}

export const breakpoints = [32, 48, 64, 80].map((n) => n + "em")
export const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60].map(
  (size) => `${(size / 9.5).toFixed(2)}rem`
)

export const fontSizes = [...Array(100)].map(
  (_, size) => `${((1 / 16) * size * 1).toFixed(2)}rem`
)

// https://seek-oss.github.io/capsize/
const fontCollection = {
  MaisonNeue: {
    fontFamily: `"MaisonNeue", helvetica, sans-serif`,
    normal: 200,
    bold: 400,
    fontSize: fontSizes[12],
    lineHeight: "1.3em",
    letterSpacing: 0,
    heightCorrection: 0.24,
    baselineCorrection: 0.31,
  },
}

export const fontWeights = {
  light: 200,
  normal: 400,
  bold: 700,
}

export const textStyles = {
  title: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[16],
    letterSpacing: 0.4,
  },
  headline: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[30],
  },
  body: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[16],
    letterSpacing: 0.12,
    textTransform: "uppercase"
  },
  more: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[16],
    letterSpacing: 0.025,
  },
  navigation: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[16],
  },
  callout: fontCollection.MaisonNeue,
  subhead: { ...fontCollection.MaisonNeue, fontSize: fontSizes[20] },
  footnote: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[16],
  },
  caption: {
    ...fontCollection.MaisonNeue,
    fontSize: fontSizes[10],
    letterSpacing: 0.12,
  },
  action: {
    ...fontCollection.MaisonNeue,
    fontWeight: "normal",
  },
}

export const radii = [0, 4, 8]

export const shadows = ["none", "0 10px 30px 0 rgba(0,0,0,.07)"]

const maxWidth = `
    max-width: 920px;
    width: 90%;
    margin: 0 auto;
  `

export default {
  isWideBreakpoint: "820px",
  topPadding: "4.8rem",
  maxWidth,
  breakpoints,
  space,
  textStyles,
  fontSizes,
  fontWeights,
  colors,
  radii,
  shadows,
}
