import * as React from "react"
import styled from "styled-components"
import { View } from "./"
import ReactPlayer from 'react-player'

const VideoWrapper = styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
`

const FullEverything = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -150vw;
  right: -150vw;
  & * {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  & iframe {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

export const IsSafari = () => {

  var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  return is_safari;

}

export const videoUrl = (video) => `https://videodelivery.net/${video.secret}/manifest/video.${IsSafari() ? "m3u8" : "mpd"}`

const CoverVideo = ({ url, video, playing = true }) => (
  <VideoWrapper>
    <FullEverything>
      {/* 
      {url && <Vimeo
        videoLink={url}
        background
        responsive={false}
        style={{ pointerEvents: "none" }}
      />}
      {!!video && <Stream autoplay loop muted src={video.secret} width="100%" height="100%" />}
    */}
      <ReactPlayer playsinline muted playing={playing} loop url={!!video ? videoUrl(video) : url} width="100%" height="100%" style={{ pointerEvents: "none" }} />
    </FullEverything>
  </VideoWrapper>
)

export default CoverVideo
