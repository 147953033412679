import * as React from "react"

// every second, one more?!
const useInterval = (active = true, fps = 20) => {
  const [count, setCount] = React.useState(0)

  const animate = React.useCallback(() => {
    if (active) {
      const deltaTime = 1000 / fps

      setCount((prevCount) =>
        prevCount + deltaTime / 1000
      )
    }
  }, [active, fps])

  React.useEffect(() => {
    const to = window.setInterval(animate, 1000 / fps)
    return () => window.clearInterval(to)
  }, [animate, fps]) // Make sure the effect runs only once

  return count
}

export default useInterval
