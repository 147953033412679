import React, { memo, useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import imageSize, { composedUrl } from "../utilities/imageSize";
import useComponentSize from "@rehooks/component-size";

export const FadeImage = ({ src }) => {
  const [loaded, setLoaded] = useState(false);
  let img = new Image();
  useEffect(() => {
    img.onload = () => {
      setLoaded(true);
    };
    img.src = src;
    return () => {
      img.onload = null;
      img = null;
    };
  }, []);
  return loaded ? <BackgroundImage src={src} loaded={loaded} /> : null;
};

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.src});
  opacity: 0;
  animation: ${opacity} 0.7s ease forwards;
  animation-delay: 0;
  transform: translate3d(0, 0, 0);
`;

const NoFadeImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.src});
  transform: translate3d(0, 0, 0);
`;

const SlideImage = memo(
  ({ slide, fade = true, lazy = true, cover = false }) => {
    const secret = slide && slide.image && slide.image.secret;
    // Ref for the element that we want to detect whether on screen
    const ref = useRef();
    const onScreen = useOnScreen(ref);

    let { width, height } = useComponentSize(ref);
    // Call the hook passing in ref and root margin
    // In this case it would only be considered onScreen if more ...
    // ... than 300px of element is visible.
    const { url } = useDimensionsAndUrl(
      width * (cover ? 4 : 1),
      height,
      secret
    );

    return (
      <div
        ref={ref}
        style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
      >
        {width &&
          height &&
          secret &&
          (!lazy || onScreen) &&
          url &&
          (fade ? <FadeImage src={url} /> : <NoFadeImage src={url} />)}
      </div>
    );
  }
);

// Hook
const useOnScreen = (ref, rootMargin = "0px") => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export default SlideImage;

export const ImageDimensionsAndUrl = ({
  width,
  height,
  file,
  secret,
  children,
}) => {
  const dimensions = imageSize({ width, height });
  const url = composedUrl(
    null,
    null,
    dimensions.width,
    dimensions.height,
    secret
  );
  return children({ ...dimensions, url });
};

export const useDimensionsAndUrl = (width, height, secret) => {
  if (!width || !height || !secret) return { url: null };

  const dimensions = imageSize({ width, height });
  /*
  const url = composedUrl(
    null,
    null,
    dimensions.width,
    dimensions.height,
    secret
  )
  */
  const url = "https://ik.imagekit.io/whitespace/[secret]?tr=w-[width]"
    .replace("[secret]", secret)
    .replace("[width]", dimensions.width);

  return { ...dimensions, url };
};
