import * as React from "react";
import { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { View, Text, Inline } from "./components";
import { clean } from "./components/Clean";
import { useQuery } from "@apollo/client";
import ALL_ALBUMS from "./queries/ALL_ALBUMS";
import SectionArea, { GalleryArea } from "./components/SectionArea";
import LogoType from "./components/LogoType";
import InfiniteCarousel from "./components/InfiniteCarousel";
import MenuIcon from "./components/MenuIcon";
import { Link, Route, Routes, useParams, useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";
import getKeyValue from "./utilities/getKeyValue";
import Meta from "./components/Meta";
import { useMedia } from "use-media";
import MobileHeader from "./MobileHeader";
import LogoSymbol from "./components/LogoSymbol";
import MobileAlbumsOverview from "./components/MobileAlbumsOverview";
import hoverItemAtom from "./hoverItemAtom";
import ContactDetails from "./ContactDetails";
import useOnClickOutside from "./utilities/useOnClickOutside";

export const defaultSection = "film";

function n(n) {
  return n > 9 ? "" + n : "0" + n;
}

export const contentArea = atom(null);
// home ... load albums data
// create the photography and film selections

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MobileBG = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors["secondaryBackground"]};
    min-height: 110vh;
  }
`;

const MenuOverlayContainer = styled(View)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 0s 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 350ms;
`;

const CategoryChooserContainer = styled(View)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  z-index: 65;
  transform: translate3D(-50%, -50%, 0);
  transition: opacity 400ms ease-in-out;
  overflow: hidden;
`;

const MobileCarouselContainer = styled(View)`
  position: relative;
  min-height: 100%;
`;

const Line = styled(View)`
  position: absolute;
  border-bottom: 1px solid black;
  width: 30vw;
  pointer-events: none;
`;

export const MainContainer = ({ children, style, bg }) => {
  return (
    <View
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: `100%`,
        height: `100vh`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      {...(bg && { bg })}
    >
      <View
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          maxWidth: `${100 * (21 / 10)}vh`,
          maxHeight: `${100 / (3 / 2)}vw`,
        }}
      >
        {children}
      </View>
    </View>
  );
};

const CategoryChooser = ({ style }) => {
  const [rect] = useAtom(contentArea);
  console.log({ rect });
  return (
    <CategoryChooserContainer
      style={{
        width: rect?.space,
        height: rect?.height,
        ...style,
      }}
    >
      <Text
        as={Link}
        to="photography"
        style={{ position: "absolute", right: 0, bottom: 0 }}
        color="primary"
        pl={6}
      >
        PHOTOGRAPHY
        <Line style={{ right: "100%", bottom: 0 }} />
      </Text>
      <LogoSymbol style={{ minWidth: 19, maxWidth: 26 }} />
      <Text
        as={Link}
        to="film"
        style={{ position: "absolute", left: 0, top: "0.1em" }}
        color="primary"
        pr={6}
      >
        FILM
        <Line style={{ left: "100%", top: 0 }} />
      </Text>
    </CategoryChooserContainer>
  );
};

const Menu = ({
  photographyAlbums,
  filmAlbums,
  style,
  where,
  contact,
  disappearing,
  visible,
}) => {
  const [rect] = useAtom(contentArea);
  const [whereMemo, setWhereMemo] = useState(where);
  useEffect(() => {
    console.log("WHERE?!", where);
    if (!!where && !disappearing) setWhereMemo(where);
    if (!!contact) setWhereMemo("contact");
  }, [where, contact, disappearing]);
  console.log({ whereMemo, rect });

  const navigate = useNavigate();
  const ref = useRef();
  /*
  useOnClickOutside(
    ref,
    () =>
      ["film", "photography", "contact"].includes(whereMemo) &&
      navigate("/", { replace: true }),
    "menu-button"
  );
  */

  const [hovering] = useAtom(hoverItemAtom);

  return (
    <MenuOverlayContainer
      style={{
        position: "fixed",
        left: "0%",
        top: "0",
        width: "50%",
        height: "100vh",
        zIndex: 66,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "left 550ms ease-in-out, filter 250ms ease-in-out",
        filter: "opacity(0)",
        pointerEvents: "none",
        ...style,
      }}
      bg="background"
      ref={ref}
    >
      <View
        style={{
          width: rect?.contentWidth,
          height: rect?.height,
          overflowY: "auto",
        }}
      >
        <View mb={8}>
          <Text
            as={Link}
            to="film"
            mr={7}
            style={{ display: "inline-block" }}
            color={whereMemo === "film" ? "primary" : "secondary"}
            replace
            hover
          >
            FILM
          </Text>

          <Text
            as={Link}
            to="photography"
            mr={7}
            style={{ display: "inline-block" }}
            color={whereMemo === "photography" ? "primary" : "secondary"}
            replace
            hover
          >
            PHOTOGRAPHY
          </Text>

          <Text
            as={Link}
            to="contact"
            mr={0}
            style={{ display: "inline-block" }}
            color={whereMemo === "contact" ? "primary" : "secondary"}
            replace
            hover
          >
            CONTACT
          </Text>
        </View>
        {!contact &&
          whereMemo === "photography" &&
          photographyAlbums?.map((album) => (
            <Inline
              key={album.id}
              as={clean(Link)}
              style={{ textDecoration: "none" }}
              my={3}
              _to={album.slug}
              color={hovering === album.id ? "primary" : "secondary"}
              hover
            >
              <Text
                block
                color="inherit"
                flex={!getKeyValue(album, "Client")}
                style={{ paddingRight: 0 }}
              >
                {album.title}
              </Text>
              {!!getKeyValue(album, "Client") && (
                <Text textStyle="more" color="inherit" flex>
                  {"‎ / "}
                  {getKeyValue(album, "Client")}
                </Text>
              )}
              <Text
                color="inherit"
                style={{ fontVariantNumeric: "tabular-nums", letterSpacing: 0 }}
              >
                {"։ "}
                {/* ᠄︓ */}
                {n(album.slidesConnection.totalCount)}
              </Text>
            </Inline>
          ))}

        {!contact &&
          whereMemo === "film" &&
          filmAlbums?.map((album) => (
            <Inline
              key={album.id}
              as={clean(Link)}
              style={{ textDecoration: "none" }}
              my={3}
              _to={album.slug}
              color={hovering === album.id ? "primary" : "secondary"}
              hover
            >
              <Text
                block
                color={hovering === album.id ? "primary" : "secondary"}
                hover
              >
                {!!getKeyValue(album, "Client") &&
                  `${getKeyValue(album, "Client").toUpperCase()}`}
              </Text>
              <Text
                textStyle={!!getKeyValue(album, "Client") ? "more" : "body"}
              >
                {!!getKeyValue(album, "Client") && "‎ / "}
                {album.title}
              </Text>
            </Inline>
          ))}

        {whereMemo === "contact" && (
          <View
            style={{ textDecoration: "none", color: "secondary" }}
            my={1}
            block
          >
            <ContactDetails />
          </View>
        )}
      </View>
    </MenuOverlayContainer>
  );
};

const getWhere = (params) =>
  ["film"].includes(params["*"])
    ? "film"
    : ["photography"].includes(params["*"])
    ? "photography"
    : null;

// Fisher-Yates (aka Knuth) Shuffle
// https://bost.ocks.org/mike/shuffle/
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const Home = ({ children }) => {
  const { data } = useQuery(ALL_ALBUMS, {
    variables: { username: process.env.REACT_APP_WHITESPACE_USER },
  });
  const photographyAlbums = data?.userByUsername?.albums?.filter((album) =>
    album.tags.find((tag) => tag.name === "Photography")
  );
  const filmAlbums = data?.userByUsername?.albums?.filter((album) =>
    album.tags.find((tag) => tag.name === "Film")
  );

  const photographyCarouselAlbums = photographyAlbums?.filter((album) =>
    album.tags.find((tag) => tag.name === "Carousel")
  );

  const filmCarouselAlbums = filmAlbums?.filter((album) =>
    album.tags.find((tag) => tag.name === "Carousel")
  );

  const params = useParams();
  const [wereLast, setWhere] = useState(getWhere(params));

  console.log({ photographyAlbums });
  console.log({ filmAlbums });

  const [paused, setPaused] = React.useState(false);
  React.useEffect(() => {
    setPaused(
      params["*"].length > 0 && !["film", "photography"].includes(params["*"])
    );
  }, [params]);

  useEffect(() => {
    if (
      ["photography", "film"].includes(params["*"]) ||
      params["*"].length === 0
    ) {
      setWhere(getWhere(params));
    }
  }, [params]);

  useEffect(() => {
    if (
      ["contact"].includes(params["*"]) &&
      !["photography", "film"].includes(wereLast)
    ) {
      setWhere(defaultSection);
    }
  }, [params, wereLast]);

  const contact = ["contact"].includes(params["*"]);

  const mobile = useMedia({ maxWidth: 800 });

  const mobileSelection = React.useMemo(() => {
    return (
      mobile &&
      !!photographyCarouselAlbums &&
      !!filmCarouselAlbums &&
      shuffle([...photographyCarouselAlbums, ...filmCarouselAlbums]).slice(0, 5)
    );
  }, [photographyCarouselAlbums, filmCarouselAlbums, mobile]);

  return mobile ? (
    <>
      <MobileBG />
      <MobileHeader
        photographyAlbums={photographyAlbums}
        filmAlbums={filmAlbums}
        wereLast={wereLast}
      >
        <MobileCarouselContainer>
          {photographyAlbums && (
            <InfiniteCarousel
              style={{ position: "absolute" }}
              slides={mobileSelection.reduce(
                (collect, album) =>
                  album.slides.length > 0
                    ? [...collect, { ...album.slides[0], slug: album.slug }]
                    : collect,
                []
              )}
              paused={paused}
              full
            />
          )}
        </MobileCarouselContainer>
      </MobileHeader>
      <View bg="secondaryBackground" pt={[9, 10, 10]}>
        {children}

        <Routes>
          <Route
            path="/film"
            element={<MobileAlbumsOverview where="FILM" albums={filmAlbums} />}
          />
          <Route
            path="/photography"
            element={
              <MobileAlbumsOverview
                where="PHOTOGRAPHY"
                albums={photographyAlbums}
              />
            }
          />
        </Routes>
      </View>
    </>
  ) : (
    <>
      <MainContainer>
        <SectionArea setRect>
          {photographyAlbums && (
            <InfiniteCarousel
              slides={photographyCarouselAlbums.reduce(
                (collect, album) =>
                  album.slides.length > 0
                    ? [
                        ...collect,
                        {
                          ...album.slides[0],
                          slug: album.slug,
                          albumId: album.id,
                          album: album,
                        },
                      ]
                    : collect,
                []
              )}
              paused={paused}
            />
          )}
        </SectionArea>
        <CategoryChooser
          {...(params["*"].length > 0 && {
            style: { opacity: 0, pointerEvents: "none" },
          })}
        />
        <SectionArea style={{ left: "50%" }}>
          {filmAlbums && (
            <InfiniteCarousel
              slides={filmCarouselAlbums.reduce(
                (collect, album) =>
                  album.slides.length > 0
                    ? [
                        ...collect,
                        {
                          ...album.slides[0],
                          slug: album.slug,
                          albumId: album.id,
                          album: album,
                        },
                      ]
                    : collect,
                []
              )}
              direction="down"
              paused={paused}
            />
          )}
        </SectionArea>
        <Menu
          photographyAlbums={photographyAlbums}
          filmAlbums={filmAlbums}
          where={wereLast}
          style={{
            ...(wereLast === "film" && {
              left: "0%",
              filter: "opacity(1)",
              pointerEvents: "auto",
            }),
            ...(wereLast === "photography" && {
              left: "50%",
              filter: "opacity(1)",
              pointerEvents: "auto",
            }),
          }}
          contact={contact}
          disappearing={params["*"].length === 0}
          key={!!wereLast ? "there" : "nere"}
        />
      </MainContainer>
      {children}
      <MainContainer style={{ pointerEvents: "none", zIndex: 200 }}>
        <SectionArea bg={null} className="overlayd-elements">
          <LogoType />
          <Meta left />
          <Meta center />
        </SectionArea>
        <SectionArea
          style={{ left: "50%" }}
          bg={null}
          className="overlayd-elements"
        >
          <MenuIcon
            active={
              params["*"].length > 0
              // && !["film", "photography", "contact"].includes(params["*"])
            }
            home={params["*"].length === 0}
            open={["film", "photography", "contact"].includes(params["*"])}
          />
          <Meta right />
        </SectionArea>
      </MainContainer>
      <MainContainer
        bg="secondaryBackground"
        style={{ position: "fixed", zIndex: -1 }}
      >
        <GalleryArea setRect></GalleryArea>
      </MainContainer>
    </>
  );
};

export default Home;
