import * as React from "react";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Slider,
  Direction,
  FormattedTime,
  PlayerIcon,
} from "react-player-controls";
import styled, { createGlobalStyle } from "styled-components";
import View from "./View";
import detectIt from "detect-it";
// import canAutoplay from "can-autoplay"
import Text from "./Text";
import { useSpring, config } from "react-spring";
import useFullscreen from "@rooks/use-fullscreen";
import FullScreenToggle from "./FullScreenToggle";
import useMousePosition from "@react-hook/mouse-position";

const Delay = ({ children }) => {
  const [show, set] = useState(true);
  useEffect(() => {
    const to = setTimeout(() => {
      set(true);
      window.alert("GOGOGO");
    }, 2000);
    return () => clearTimeout(to);
  }, [set]);
  return show === true ? <>{children}</> : null;
};

const HideOverlaydElements = createGlobalStyle`
  .overlayd-elements {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
`;

const usePlayerState = (player, autoPlay) => {
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(
    !!window.sessionStorage.getItem("volume1") ? 1 : 0
  );
  // const [trueProgress, setTrueProgress] = useState(0);
  const [progress, setSpringProgress] = useState(0);
  const [isPlaying, setPlaying] = useState(autoPlay);
  const [pausedHandle, setPausedHandle] = useState(false);

  const stop = () => {
    setPlaying(false);
    setSpringProgress(0);
  };

  const start = () => {
    setPlaying(true);
  };

  const toggleFullScreen = () => {
    if (player?.current.requestFullScreen) {
      player.current.requestFullScreen();
    } else if (player?.current.webkitRequestFullScreen) {
      player.current.webkitRequestFullScreen();
    } else if (player?.current.mozRequestFullScreen) {
      player.current.mozRequestFullScreen();
    }
  };

  const [, set] = useSpring(() => ({
    progress: 0,
    // config: { mass: 1, tension: 500, friction: 120, precision: 0.5 },
    onFrame: ({ progress }) => setSpringProgress(progress),
  }));

  const setProgress = (value) => {
    if (!pausedHandle) {
      set({ progress: value.playedSeconds });
    } else {
      console.log("PAUSED");
    }
  };

  return {
    duration,
    setDuration,
    volume,
    progress,
    setProgress,
    isPlaying,
    setPlaying: () => setPlaying(true),
    setPause: () => setPlaying(false),
    toggle: () => (isPlaying ? setPlaying(false) : setPlaying(true)),
    setVolume,
    start,
    stop,
    toggleFullScreen,
    setPausedHandle,
  };
};

const StyledPlayer = styled(ReactPlayer)`
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }
`;

const ControlsContainer = styled(View)`
  display: flex;
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  transition: opacity 300ms ease-in-out;
  align-items: center;
`;

const WHITE_SMOKE = "rgba(0,0,0,0.35)";
const GRAY = "rgba(0,0,0,0.25)";

// A colored bar that will represent the current value
const SliderBar = ({ direction, value, style, right }) => (
  <div
    style={Object.assign(
      {},
      {
        position: "absolute",
        background: GRAY,
      },
      direction === Direction.HORIZONTAL
        ? {
            top: 0,
            bottom: 0,

            width: `calc(${value * 100}% - 6px)`,
            ...(right
              ? {
                  right: 0,
                }
              : {
                  left: 0,
                }),
          }
        : {
            right: 0,
            bottom: 0,
            left: 0,
            height: `${value * 100}%`,
          },
      style
    )}
  />
);

// A handle to indicate the current value

const Handle = styled(View)`
  position: relative;
  background-color: transparent;
  :hover {
    background-color: white;
  }
  transition: background-color 300ms ease-out;
  border: 1px solid white;
  :after {
    content: "";
    position: absolute;
    left: -12px;
    right: -12px;
    top: -12px;
    bottom: -12px;
  }
`;

const SliderHandle = ({ direction, value, style, color, ...rest }) => (
  <Handle
    style={Object.assign(
      {},
      {
        position: "absolute",
        width: 12,
        height: 12,
        background: color,
        borderRadius: "100%",
      },
      direction === Direction.HORIZONTAL
        ? {
            top: 0,
            left: `${value * 100}%`,
            marginTop: -6,
            marginLeft: -7,
          }
        : {
            left: 0,
            bottom: `${value * 100}%`,
            marginBottom: -8,
            marginLeft: -4,
          },
      style
    )}
    {...rest}
  />
);

const LoadingBar = ({ color }) => {
  return <div className="vrt-audio-player__loading-icon" />;
};

const ProgressBar = ({
  value,
  maxValue,
  valueChanged,
  setPausedHandle,
  ...props
}) => {
  return (
    <Slider
      className="AudioPlayer__Slider"
      direction={Direction.HORIZONTAL}
      onChange={(newPosition) => valueChanged(newPosition)}
      style={{
        // width: "100%",
        height: 2,
        //   transition: "width 0.1s",
        cursor: "pointer",
        flex: 1,
      }}
      {...props}
    >
      <SliderBar
        className="AudioPlayer__sliderbar"
        direction={Direction.HORIZONTAL}
        value={value / maxValue}
        style={{ background: "white" }}
      />
      <SliderBar
        className="AudioPlayer__sliderbar"
        direction={Direction.HORIZONTAL}
        value={1 - value / maxValue}
        style={{ background: "rgba(255, 255, 255, 0.2)" }}
        right
      />
      <SliderHandle
        className="AudioPlayer__sliderhandle"
        direction={Direction.HORIZONTAL}
        value={value / maxValue}
        onMouseOver={() => setPausedHandle(true)}
        onMouseOut={() => setPausedHandle(false)}
      />
    </Slider>
  );
};

const Side = styled(View).attrs(() => ({ px: [4, 6, 7], color: "white" }))``;

const Time = styled(Text).attrs(() => ({ textStyle: "more", color: "white" }))`
  font-variant-numeric: tabular-nums;
`;

const VideoPlayer = ({ url, isPhone, children, ...rest }) => {
  const container = React.useRef();

  const mousePosition = useMousePosition(container, {
    enterDelay: 100,
    leaveDelay: 100,
    fps: 10, // fps
  });

  const player = React.useRef();
  const touchOnly = detectIt.deviceType === "touchOnly";

  const [autoPlay] = React.useState(!!window.sessionStorage.getItem("volume1"));

  const playerState = usePlayerState(player, autoPlay);

  const {
    isEnabled,
    toggle,
    onChange,
    onError,
    request,
    exit,
    isFullscreen,
    element,
  } = useFullscreen();

  const [doubleIsFullscreen, setFullscreen] = React.useState(false);

  /*
  React.useLayoutEffect(() => {
    canAutoplay.video({ timeout: 100, muted: false, playsinline: true }).then((obj) => {
      if (obj.result === true) {
        // Can auto-play
        playerState.setPlaying()
      } else {
        // Can not auto-play
      }
    })
  }, [])
  */
  // touchOnly ? () => playerState.toggleFullScreen() :
  const changeWidth = () => {
    console.log(container.current?.getBoundingClientRect()?.height);
    console.log(window.screen.height);
    if (
      container.current?.getBoundingClientRect()?.height ===
      window.screen.height
    ) {
      console.log("browser is in full screen");
      // alert("browser is in full screen")
      setFullscreen(true);
    } else {
      console.log("Browser in normal screen");
      setFullscreen(false);
    }
  };
  React.useEffect(() => {
    const iv = setInterval(changeWidth, 2000);
    return () => {
      clearInterval(iv);
    };
  });

  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {
    let to;
    if (hover) {
      to = setTimeout(() => setHover(false), 2000);
    }
    return () => !!to && clearTimeout(to);
  }, [hover, setHover]);

  React.useEffect(() => {
    setHover(true);
  }, [mousePosition, setHover]);

  console.log("PLAYING", playerState.isPlaying);
  console.log({ url });
  return (
    <View
      bg="black"
      ref={container}
      style={{ zIndex: 19876 }}
      absolute
      style={{ left: 0, right: 0, top: 0, bottom: 0 }}
      onClick={() => {
        playerState.setVolume(1);
        window.sessionStorage.setItem("volume1", 1);
      }}
    >
      <StyledPlayer
        background={true}
        controls={false}
        url={url}
        {...rest}
        ref={player}
        playsinline
        playing={playerState.isPlaying}
        volume={playerState.volume}
        onDuration={playerState.setDuration}
        onStart={playerState.setPlaying}
        onPlay={playerState.setPlaying}
        onPause={playerState.setPause}
        onProgress={playerState.setProgress}
        progressInterval={25}
        onClick={playerState.toggle}
      />
      <View
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          opacity: hover || !playerState.isPlaying ? 1 : 0,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          transition: "opacity 300ms ease",
        }}
        absolute
      >
        <View
          onTouchStart={() => setHover((currentState) => !currentState)}
          style={{ left: 0, right: 0, top: 0, bottom: 0 }}
          absolute
        />
        {children}
      </View>
      <ControlsContainer
        style={{ opacity: hover || !playerState.isPlaying ? 1 : 0 }}
      >
        <Side>
          <View hover pointer onClick={playerState.toggle}>
            {playerState.isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-pause"
              >
                <rect x="6" y="4" width="4" height="16"></rect>
                <rect x="14" y="4" width="4" height="16"></rect>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-play"
              >
                <polygon points="5 3 19 12 5 21 5 3"></polygon>
              </svg>
            )}
          </View>
        </Side>
        <ProgressBar
          value={playerState.progress}
          maxValue={playerState.duration}
          valueChanged={(value) => player.current.seekTo(parseFloat(value))}
          setPausedHandle={playerState.setPausedHandle}
        />
        <Side>
          <Time>
            <FormattedTime
              numSeconds={playerState.duration - playerState.progress || 0}
            />
          </Time>
        </Side>
      </ControlsContainer>
      {!isPhone && (
        <View
          pointer
          style={{
            opacity: hover || !playerState.isPlaying ? 1 : 0,
            position: "absolute",
            right: 20,
            top: 20,
            color: "white",
            width: 36,
            height: 36,
          }}
          onMouseDown={() => {
            if (!doubleIsFullscreen) {
              setFullscreen(true);
            } else {
              setTimeout(() => {
                setFullscreen(false);
              }, 500);
            }
          }}
          onClick={() => toggle(container.current)}
        >
          <FullScreenToggle active={isFullscreen} />
        </View>
      )}
      {!!doubleIsFullscreen && <HideOverlaydElements />}
    </View>
  );
};

export default VideoPlayer;
