import * as React from "react"

const Clean = (props) => {
  // make sure styled-system props do not end up in the dom
  // ...only the ones prefixed with an underscore!

  const Component = props.as || "div"
  const allowList = ["className", "children", "style", "onClick"]
  const filtered = Object.keys(props).reduce((collect, key) => {
    return key.indexOf("_") === 0
      ? { ...collect, [key.substr(1)]: props[key] }
      : { ...collect, ...(allowList.includes(key) && { [key]: props[key] }) }
  }, {})
  return <Component {...filtered} />
}

export const clean = (Component) => (props) => (
  <Clean as={Component} {...props} />
)

export default Clean
