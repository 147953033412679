import * as React from "react"
import { Text, View } from "./"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { clean } from "./Clean"

const LogoText = styled(Text).attrs(() => ({
  textStyle: "title",
  as: clean(Link),
  _to: "/",
  replace: true
}))`
  text-transform: uppercase;
  position: absolute;
  left: 0%;
  top: -20%;
  font-feature-settings: "kern" 1, "liga" 1, "tnum" 1;
  color: rgba(0, 0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  letter-spacing: 0.5em;

  ${({ relative }) =>
    relative &&
    `
    position: relative;
    left: 0;
    top: 0;
  `}
`

const SVG = () => (
  <svg
    viewBox="0 0 202 10"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      left: 0,
      width: "100%",
      marginTop: "0.24em",
      pointerEvents: "none",
    }}
  >
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0,0H195.821V-10H0Z" data-name="Path 3" />
      </clipPath>
    </defs>
    <g transform="translate(0 10)" data-name="Group 1">
      <g data-name="Group 4" clipPath="url(#a)">
        <g transform="translate(0 -9.795)" data-name="Group 3">
          <path
            fill="#2b2e34"
            d="M0,0H3c2.12,0,4.145,1.464,4.145,4.774,0,3.666-2.107,4.8-4.227,4.8H0ZM2.818,8.536c1.915,0,3.119-1.053,3.119-3.762,0-2.449-1.19-3.735-3.037-3.735H1.136v7.5Z"
            data-name="Path 2"
          />
        </g>
      </g>
      <g transform="translate(17.347 -9.795)" data-name="Group 5">
        <path
          fill="#2b2e34"
          d="M0,0-3.447,9.576H-2.3L-1.6,7.7H2.695l.711,1.875H4.555L1.108,0ZM.534,1.518H.561l1.8,5.2H-1.258Z"
          data-name="Path 4"
        />
      </g>
      <g transform="translate(35.174 -9.795)" data-name="Group 6">
        <path
          fill="#2b2e34"
          d="M0,0V7.7H-.027L-4.391,0H-5.622V9.576h1.136v-7.7h.026l4.392,7.7h1.19V0Z"
          data-name="Path 5"
        />
      </g>
      <path fill="#2b2e34" d="M45.8-.219h1.135V-9.8H45.8Z" data-name="Path 6" />
      <g transform="translate(56.437 -9.795)" data-name="Group 7">
        <path
          fill="#2b2e34"
          d="M0,0V9.576H5.759V8.536H1.136V5.157H5.377V4.145H1.136V0Z"
          data-name="Path 7"
        />
      </g>
      <g transform="translate(71.289 -9.795)" data-name="Group 8">
        <path
          fill="#2b2e34"
          d="M0,0V9.576H5.3V8.536H1.136V0Z"
          data-name="Path 8"
        />
      </g>
      <g data-name="Group 14" clipPath="url(#a)">
        <g transform="translate(94.702 -9.795)" data-name="Group 9">
          <path
            fill="#2b2e34"
            d="M0,0H3.68A2.516,2.516,0,0,1,6.471,2.476a1.954,1.954,0,0,1-1.19,1.915v.041A2.313,2.313,0,0,1,6.826,6.744c0,1.723-1.012,2.832-3.378,2.832H0ZM3.475,4.049c1.286,0,1.874-.547,1.874-1.5,0-.985-.711-1.5-1.888-1.5H1.136v3.01ZM3.3,8.536c1.546,0,2.408-.493,2.408-1.792,0-1.245-.794-1.683-2.381-1.683H1.136V8.536Z"
            data-name="Path 9"
          />
        </g>
        <g transform="translate(109.87 -5.089)" data-name="Group 10">
          <path
            fill="#2b2e34"
            d="M0,0C0-3.3,1.984-4.911,4.063-4.911,6.1-4.911,8.071-3.242,8.071.055c0,3.666-1.97,5.02-4.008,5.02C2,5.075,0,3.666,0,0M6.867.055c0-2.654-1.3-3.885-2.8-3.885C2.531-3.83,1.2-2.654,1.2,0c0,2.736,1.245,4.008,2.859,4.008,1.573,0,2.8-1.217,2.8-3.953"
            data-name="Path 10"
          />
        </g>
        <g transform="translate(126.62 -9.795)" data-name="Group 11">
          <path
            fill="#2b2e34"
            d="M0,0H1.135V6.374a2.05,2.05,0,0,0,2.258,2.34A2.06,2.06,0,0,0,5.677,6.4V0H6.8V6.456c0,2.217-1.286,3.325-3.406,3.325S0,8.673,0,6.456Z"
            data-name="Path 11"
          />
        </g>
        <g transform="translate(148.56 -.862)" data-name="Group 12">
          <path
            fill="#2b2e34"
            d="M0,0A3.7,3.7,0,0,1-2.394.848C-4.46.848-6.457-.561-6.457-4.227c0-3.3,1.984-4.911,4.063-4.911,2.038,0,4.008,1.669,4.008,4.966A5.839,5.839,0,0,1,.629-.657l.9.89L.875.862ZM-1.068-2.331l.9.881A5.381,5.381,0,0,0,.41-4.172c0-2.654-1.3-3.885-2.8-3.885-1.532,0-2.859,1.176-2.859,3.83,0,2.736,1.245,4.008,2.859,4.008A2.524,2.524,0,0,0-.752-.752l-.972-.95Z"
            data-name="Path 12"
          />
        </g>
        <g transform="translate(158.85 -9.795)" data-name="Group 13">
          <path
            fill="#2b2e34"
            d="M0,0H1.136V6.374a2.049,2.049,0,0,0,2.257,2.34A2.06,2.06,0,0,0,5.677,6.4V0H6.8V6.456c0,2.217-1.286,3.325-3.406,3.325S0,8.673,0,6.456Z"
            data-name="Path 13"
          />
        </g>
      </g>
      <g transform="translate(174.88 -9.795)" data-name="Group 15">
        <path
          fill="#2b2e34"
          d="M0,0V9.576H5.759V8.536H1.135V5.157H5.377V4.145H1.135V1.039H5.759V0Z"
          data-name="Path 15"
        />
      </g>
      <g transform="translate(188.78 -9.795)" data-name="Group 16">
        <path
          fill="#2b2e34"
          d="M0,0V1.039H2.968V9.576H4.09V1.039H7.044V0Z"
          data-name="Path 16"
        />
      </g>
      <path
        fill="#2b2e34"
        d="M61.157-8.755H62.2V-9.8h-1.04Z"
        data-name="Path 17"
      />
      <path
        fill="#2b2e34"
        d="M58.8-8.755h1.04V-9.8H58.8Z"
        data-name="Path 18"
      />
    </g>
  </svg>
)

const LogoType = ({ relative, ...rest }) => (
  <LogoText relative={relative} {...rest}>
    Daniel Bouquet
    <SVG />
  </LogoText>
)

export default LogoType
