import React, { Children } from "react"
import View from "./View"
import styled from "styled-components"

const mapToFlexJustify = (justify) => {
  const values = {
    left: "flex-start",
    center: "center",
    right: "flex-end",
    "space-between": "space-between",
  }

  return values[justify]
}

const mapToFlexAlign = (align) => {
  const values = {
    top: "flex-start",
    center: "center",
    bottom: "flex-end",
  }

  return values[align]
}

const Wrapper = styled(View)`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  margin-left: ${({ theme, gap }) => -1 * theme.space[gap]}px;
  margin-top: ${({ theme, gap }) => -1 * theme.space[gap]}px;

  transition: color 250ms ease;

  ${({ hover }) =>
    hover === true &&
    `
    :hover {
      color: black;
    }
  `};
`

const Flex = styled(View)`
  ${({ flex }) =>
    flex &&
    `
    flex: 1
  `}
`

const Inline = ({
  children,
  gap = 0,
  justify = "normal",
  verticalAlign = "bottom",
  ...rest
}) => {
  const justifyContent = mapToFlexJustify(justify)
  const alignItems = mapToFlexAlign(verticalAlign)
  /*
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (!child) return null
    if (index === 0 || index === children.length) {
      return child
    }
    const addProps =
      child.props.m || child.props.my
        ? null
        : {
            mt: child.props.mt || gap,
            mb: child.props.mb || (index < children.length - 1 && gap)
          }
    return React.cloneElement(child, addProps)
  })
  */
  return (
    <Wrapper
      gap={gap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...rest}
    >
      {Children.map(children, (child) =>
        child !== null && child !== undefined ? (
          <Flex pr={gap} flex={child.props.flex}>
            {child}
          </Flex>
        ) : null
      )}
    </Wrapper>
  )
}

export default Inline
