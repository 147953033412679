import * as React from "react"
import Text from "./Text"
import View from "./View"

const Item = ({ children, slide, value, index, correct, space = 2.5, slidesCount }) => {
  // item zichtbaar of niet?
  const [initialized, setIinitialized] = React.useState(false)

  // they need loopin'

  // +1?! afhankelijk van de richting?
  const pos = (((100 + space) * (index + (value > 0 ? 1 : -1))) + value)
  const times = pos / (slidesCount * (100 + space))
  const absTimes = times > 0 ? Math.floor(times) : Math.ceil(times)
  const correctCycles = absTimes * slidesCount * (100 + space)
  const defPos = ((100 + space) * index) + value - correctCycles

  const relativeCorrect = (100 + space) * index
  const inView = defPos > -140 && defPos < 140

  React.useEffect(() => {
    if (!!inView) {
      setIinitialized(true)
    }
  }, [inView])

  if (!initialized) return null

  return children(inView, `${defPos}%`)

  return <>

    <Text style={{ marginLeft: 300 }} mr={0}>
      {index} - {absTimes}
    </Text>
    <View bg={inView ? "rgba(0,0,0,0.2)" : "red"} style={inView ? { position: "absolute", top: `${defPos}%`, height: "100%", width: 260 } : { display: "none" }}>
      <Text >{index} {slide.album.title} <br />{correct} <br />Value: {value}<br />Total: {slidesCount}<br />relativeCorrect: {(100 + space) * index}

        <br />
TOP: {((100 + space) * index) + value}

        <br />
        {(relativeCorrect + (((100 + space) * index) + value)) > -100 ? "YES" : "NO"}
        <br />% {slidesCount} - {value / 100}
        <br />times: {times}
        <br />ABS: {absTimes}
        <br />correct cycles: {correctCycles}
      </Text>

    </View></>
}

export default Item